<template>
    <div>
        <div class="surface-card p-6 border-1 surface-border">
            <data-table></data-table>
        </div>
    </div>
</template>

<script>
    import DataTable from "@/components/data/request/DataTable";

    export default {
        components: {DataTable},
    }
</script>
